import React from 'react';
import { Box, List, Drawer, ListItemIcon, ListItemText, ListItemButton, Collapse, Typography, IconButton, useTheme, useMediaQuery, Avatar, Badge, Menu, MenuItem } from '@mui/material';
import {
    AccessTime as TimeIcon,
    BarChart as ExpenseIcon,
    Payment as PaymentIcon,
    Description as InvoiceIcon,
    Settings as SettingsIcon,
    Security as TrustIcon,
    Refresh as ReplenishIcon,
    KeyboardArrowDown as ArrowDownIcon,
    KeyboardArrowUp as ArrowUpIcon,
    NotificationsNone as NotificationIcon,
    Menu as MenuIcon,
    PlaylistAdd as TimeEntryIcon,
    Search as TimeSearchIcon,
    Receipt as NewExpenseIcon,
    FindInPage as SearchExpenseIcon,
    AccountBalanceWallet as MakePaymentIcon,
    Logout as LogoutIcon
} from '@mui/icons-material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../static/img/logo.png'
import DashboardContent from '../components/dashboard/DashboardContent';
import AppBar from '../components/AppBar';

const Dashboard = () => {
    const [selectedMainMenu, setSelectedMainMenu] = useState(null);
    const [selectedPath, setSelectedPath] = useState('/');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleMainMenuClick = (menuId) => {
        setSelectedMainMenu(menuId === selectedMainMenu ? null : menuId);
        if (isMobile) setMobileOpen(false);
    };

    const handleContentChange = (path) => {
        setSelectedPath(path);
        if (isMobile) setMobileOpen(false);
    };

    const menuItems = [
        {
            id: 'time',
            icon: <TimeIcon />,
            label: 'Time',
            subItems: [
                { id: 'timeEntry', label: 'Time Entry', icon: <TimeEntryIcon />, path: '/time/entry' },
                { id: 'timeSearch', label: 'Search Time', icon: <TimeSearchIcon />, path: '/time/search' }
            ]
        },
        {
            id: 'expense',
            icon: <ExpenseIcon />,
            label: 'Expense',
            subItems: [
                { id: 'newExpense', label: 'New Expense', icon: <NewExpenseIcon />, path: '/expense/new' },
                { id: 'searchExpense', label: 'Search Expenses', icon: <SearchExpenseIcon />, path: '/expense/search' }
            ]
        },
        {
            id: 'payments',
            icon: <PaymentIcon />,
            label: 'Payments',
            subItems: [
                { id: 'makePayment', label: 'Make Payment', icon: <MakePaymentIcon />, path: '/payments/new' },
                { id: 'trust', label: 'Trust Account', icon: <TrustIcon />, path: '/payments/trust' },
                { id: 'replenish', label: 'Replenish', icon: <ReplenishIcon />, path: '/payments/replenish' }
            ]
        },
        {
            id: 'invoices',
            icon: <InvoiceIcon />,
            label: 'Invoices',
            subItems: [
                { id: 'drafts', label: 'Draft Invoices', icon: <InvoiceIcon />, path: '/invoices/drafts' },
                { id: 'sent', label: 'Sent Invoices', icon: <InvoiceIcon />, path: '/invoices/sent' }
            ]
        }
    ];

    const mainSidebarContent = (
        <Box sx={{
            width: 72,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 1,
            gap: 1,
            bgcolor: 'background.paper'
        }}>
            {menuItems.map((item) => (
                <IconButton
                    key={item.id}
                    onClick={() => handleMainMenuClick(item.id)}
                    sx={{
                        width: 48,
                        height: 48,
                        borderRadius: '50%',
                        bgcolor: selectedMainMenu === item.id ? 'primary.main' : 'transparent',
                        color: selectedMainMenu === item.id ? 
                            (theme) => theme.palette.mode === 'light' ? 'white' : 'black' : 
                            'text.primary',
                        '&:hover': {
                            bgcolor: 'action.hover',
                        }
                    }}
                >
                    {item.icon}
                </IconButton>
            ))}
        </Box>
    );

    const subMenuContent = selectedMainMenu && (
        <Box sx={{
            width: 240,
            bgcolor: 'background.paper',
            height: '100vh'
        }}>
            <Typography variant="h6" sx={{ p: 2 }}>
                {menuItems.find(item => item.id === selectedMainMenu)?.label}
            </Typography>
            <List>
                {menuItems
                    .find(item => item.id === selectedMainMenu)
                    ?.subItems?.map((subItem) => (
                        <ListItemButton
                            key={subItem.id}
                            onClick={() => handleContentChange(subItem.path)}
                            selected={selectedPath === subItem.path}
                            sx={{
                                '&:hover': {
                                    bgcolor: 'action.hover',
                                }
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: 40 }}>{subItem.icon}</ListItemIcon>
                            <ListItemText primary={subItem.label} />
                        </ListItemButton>
                    ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            height: '100vh',
            bgcolor: 'background.default'
        }}>
            <AppBar isMobile={isMobile} onMenuClick={() => setMobileOpen(!mobileOpen)} />
            <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                {isMobile ? (
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        open={mobileOpen}
                        onClose={() => setMobileOpen(false)}
                        ModalProps={{ keepMounted: true }}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: 312, // 72 + 240
                                boxSizing: 'border-box',
                            },
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            {mainSidebarContent}
                            {subMenuContent}
                        </Box>
                    </Drawer>
                ) : (
                    <>
                        <Box component="nav" sx={{ display: 'flex' }}>
                            {mainSidebarContent}
                            {subMenuContent}
                        </Box>
                    </>
                )}
                <Box sx={{ flex: 1, p: 3, overflow: 'auto' }}>
                    <DashboardContent selectedPath={selectedPath} />
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;