import React from 'react';
import { Typography, Box, Grid2 } from '@mui/material';

const MakePayment = () => (
    <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>Make Payment</Typography>
        <Grid2 container spacing={3}>
            <Grid2 item xs={12} md={6}>
                <Box sx={{ p: 2, }}>
                    <Typography variant="subtitle1">Payment Methods</Typography>
                    <Typography variant="body2"> functionality will be implemented here</Typography>
                </Box>
            </Grid2>
        </Grid2>
    </Box>
);

export default MakePayment;