import React from 'react';
import { Box, List, Drawer, ListItemIcon, ListItemText, ListItemButton, Collapse, Typography, IconButton, useTheme, useMediaQuery, Avatar, Badge } from '@mui/material';
import {
    AccessTime as TimeIcon,
    BarChart as ExpenseIcon,
    Payment as PaymentIcon,
    Description as InvoiceIcon,
    Settings as SettingsIcon,
    Security as TrustIcon,
    Refresh as ReplenishIcon,
    KeyboardArrowDown as ArrowDownIcon,
    KeyboardArrowUp as ArrowUpIcon,
    NotificationsNone as NotificationIcon,
    Menu as MenuIcon,
    PlaylistAdd as TimeEntryIcon,
    Search as TimeSearchIcon,
    Receipt as NewExpenseIcon,
    FindInPage as SearchExpenseIcon,
    AccountBalanceWallet as MakePaymentIcon
} from '@mui/icons-material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../static/img/logo.png'
import DashboardContent from '../components/dashboard/DashboardContent';

const Header = ({ isMobile, onMenuClick }) => {
    const navigate = useNavigate();

    const handleProfileClick = () => {
        navigate('/signup'); 
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 1,
            px: 2,
            borderBottom: 1,
            borderColor: 'divider',
            width: '100%',
            boxSizing: 'border-box',
            minWidth: 0
        }}>
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: { xs: 1, sm: 2 },
                minWidth: 0
            }}>
                {isMobile && (
                    <IconButton onClick={onMenuClick} sx={{ mr: 0 }}>
                        <MenuIcon />
                    </IconButton>
                )}
                <img src={Logo} alt="GoToBills Logo" style={{ height: '28px', width: 'auto' }} />
                <Typography 
                    variant="h4" 
                    fontWeight="bold"
                    sx={{ 
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    GoToBills
                </Typography>
            </Box>
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: { xs: 1, sm: 2 },
                minWidth: 0
            }}>
                <IconButton size="small">
                    <Badge badgeContent={3} color="error">
                        <NotificationIcon sx={{ fontSize: { xs: 20, sm: 24 } }} />
                    </Badge>
                </IconButton>
                <Box 
                    onClick={handleProfileClick}
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1, 
                        cursor: 'pointer',
                        minWidth: 0
                    }}
                >
                    <Avatar sx={{ 
                        width: { xs: 28, sm: 32 }, 
                        height: { xs: 28, sm: 32 } 
                    }} />
                    <Typography 
                        variant="subtitle2" 
                        sx={{ 
                            display: { xs: 'none', sm: 'block' },
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: 150
                        }}
                    >
                        Account Name
                    </Typography>
                    <ArrowDownIcon sx={{ fontSize: { xs: 16, sm: 20 } }} />
                </Box>
            </Box>
        </Box>
    );
};
    

const DashboardDropDown = () => {
    const [open, setOpen] = useState({
        time: false,
        expense: false,
        payments: false,
        invoices: false
    });
    const [mobileOpen, setMobileOpen] = useState(false);
    const [selectedPath, setSelectedPath] = useState('/');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleClick = (section) => {
        setOpen(prev => ({ ...prev, [section]: !prev[section] }));
    };

    const handleContentChange = (path) => {
        setSelectedPath(path);
        if (isMobile) setMobileOpen(false);
    };

    const menuItems = [
        {
            id: 'time',
            icon: <TimeIcon />,
            label: 'Time',
            subItems: [
                { id: 'timeEntry', label: 'Time Entry', icon: <TimeEntryIcon />, path: '/time/entry' },
                { id: 'timeSearch', label: 'Search Time', icon: <TimeSearchIcon />, path: '/time/search' }
            ]
        },
        {
            id: 'expense',
            icon: <ExpenseIcon />,
            label: 'Expense',
            subItems: [
                { id: 'newExpense', label: 'New Expense', icon: <NewExpenseIcon />, path: '/expense/new' },
                { id: 'searchExpense', label: 'Search Expenses', icon: <SearchExpenseIcon />, path: '/expense/search' }
            ]
        },
        {
            id: 'payments',
            icon: <PaymentIcon />,
            label: 'Payments',
            subItems: [
                { id: 'makePayment', label: 'Make Payment', icon: <MakePaymentIcon />, path: '/payments/new' },
                { id: 'trust', label: 'Trust Account', icon: <TrustIcon />, path: '/payments/trust' },
                { id: 'replenish', label: 'Replenish', icon: <ReplenishIcon />, path: '/payments/replenish' }
            ]
        },
        {
            id: 'invoices',
            icon: <InvoiceIcon />,
            label: 'Invoices',
            subItems: [
                { id: 'drafts', label: 'Draft Invoices', icon: <InvoiceIcon />, path: '/invoices/drafts' },
                { id: 'sent', label: 'Sent Invoices', icon: <InvoiceIcon />, path: '/invoices/sent' }
            ]
        },
        {
            id: 'settings',
            icon: <SettingsIcon />,
            label: 'Settings',
            path: '/settings'
        }
    ];

    const sidebarContent = (
        <Box sx={{
            width: 280,
            bgcolor: 'background.paper',
            height: '100vh',
            borderRight: 1,
            borderColor: 'divider',
        }}>
            <List component="nav" sx={{ pt: 0 }}>
                {menuItems.map((item) => (
                    <Box key={item.id}>
                        <ListItemButton
                            onClick={() => item.subItems ? handleClick(item.id) : handleContentChange(item.path)}
                            selected={selectedPath === item.path}
                            sx={{
                                '&:hover': {
                                    bgcolor: 'action.hover',
                                },
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.label} />
                            </Box>
                            {item.subItems && (open[item.id] ? <ArrowUpIcon /> : <ArrowDownIcon />)}
                        </ListItemButton>
                        {item.subItems && (
                            <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {item.subItems.map((subItem) => (
                                        <ListItemButton
                                            key={subItem.id}
                                            sx={{
                                                pl: 4,
                                                '&:hover': {
                                                    bgcolor: 'action.hover',
                                                }
                                            }}
                                            onClick={() => handleContentChange(subItem.path)}
                                            selected={selectedPath === subItem.path}
                                        >
                                            <ListItemIcon sx={{ minWidth: 40 }}>{subItem.icon}</ListItemIcon>
                                            <ListItemText primary={subItem.label} />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Collapse>
                        )}
                    </Box>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Header isMobile={isMobile} onMenuClick={() => setMobileOpen(!mobileOpen)} />
            <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                {isMobile ? (
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        open={mobileOpen}
                        onClose={() => setMobileOpen(false)}
                        ModalProps={{
                            keepMounted: true 
                        }}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: 280,
                                boxSizing: 'border-box',
                            },
                        }}
                    >
                        {sidebarContent}
                    </Drawer>
                ) : (
                    <Box
                        component="nav"
                        sx={{ width: 280, flexShrink: 0 }}
                    >
                        {sidebarContent}
                    </Box>
                )}
                <Box sx={{ flex: 1, p: 3, overflow: 'auto' }}>
                    <DashboardContent selectedPath={selectedPath} />
                </Box>
            </Box>
        </Box>
    );
};

export default DashboardDropDown;