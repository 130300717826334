import React from 'react';
import { Typography, Box } from '@mui/material';

const SearchExpenses = () => (
    <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>Search Expenses</Typography>
        <Box sx={{  p: 2 }}>
            <Typography>Expense search functionality will be implemented here</Typography>
        </Box>
    </Box>
);

export default SearchExpenses;