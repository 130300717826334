import React from 'react';
import { Box, Typography } from '@mui/material';

const SentInvoices = () => (
    <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>Sent Invoices</Typography>
        <Box sx={{p: 2 }}>
            <Typography> functionality will be implemented here</Typography>
        </Box>
    </Box>
);

export default SentInvoices