import React from 'react';
import { Box, Typography } from '@mui/material';
import TimeEntry from './TimeEntry'
import TimeSearch from './TimeSearch';
import NewExpense from './NewExpense';
import SearchExpense from './SearchExpenses';
import MakePayment from './MakePayment';
import TrustAccount from './TrustAccount';
import Replenish from './Replenish'
import DraftInvoices from './DraftInvoices';
import SentInvoice from './SentInvoice';

const DashboardContent = ({ selectedPath }) => {
    const renderContent = () => {
        switch (selectedPath) {
            case '/time/entry':
                return <TimeEntry />;
            case '/time/search':
                return <TimeSearch />;
            case '/expense/new':
                return <NewExpense />;
            case '/expense/search':
                return <SearchExpense />;
            case '/payments/new':
                return <MakePayment />;
            case '/payments/trust':
                return <TrustAccount />;
            case '/payments/replenish':
                return <Replenish />;
            case '/invoices/drafts':
                return <DraftInvoices />;
            case '/invoices/sent':
                return <SentInvoice />;
            default:
                return (
                    <Box sx={{ p: 3 }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>Welcome to GoToBills...</Typography>
                        <Typography variant="body1">Select an option from the sidebar to get started.</Typography>
                    </Box>
                );
        }
    };

    return renderContent();
};

export default DashboardContent;