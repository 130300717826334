import React from 'react';
import AppBar from '../components/AppBar';
import { Box, Typography, Switch, FormControlLabel, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { 
    DarkMode as DarkModeIcon, 
    LightMode as LightModeIcon,
    Person as PersonIcon,
    Notifications as NotificationsIcon,
    Security as SecurityIcon,
    Language as LanguageIcon
} from '@mui/icons-material';

const Settings = ({ onToggleTheme, isDarkMode }) => {
    const theme = useTheme();
    const [selectedSection, setSelectedSection] = React.useState('appearance');

    const settingsSections = [
        {
            id: 'appearance',
            label: 'Appearance',
            icon: isDarkMode ? <DarkModeIcon /> : <LightModeIcon />,
            content: (
                <Box>
                    <Typography variant="h6" sx={{ mb: 3 }}>Appearance Settings</Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isDarkMode}
                                onChange={onToggleTheme}
                                icon={<LightModeIcon />}
                                checkedIcon={<DarkModeIcon />}
                            />
                        }
                        label={isDarkMode ? "Dark Mode" : "Light Mode"}
                    />
                </Box>
            )
        },
        {
            id: 'profile',
            label: 'Profile',
            icon: <PersonIcon />,
            content: (
                <Box>
                    <Typography variant="h6" sx={{ mb: 3 }}>Profile Settings</Typography>
                    <Typography color="text.secondary">Profile settings will be implemented here</Typography>
                </Box>
            )
        },
        {
            id: 'notifications',
            label: 'Notifications',
            icon: <NotificationsIcon />,
            content: (
                <Box>
                    <Typography variant="h6" sx={{ mb: 3 }}>Notification Preferences</Typography>
                    <Typography color="text.secondary">Notification settings will be implemented here</Typography>
                </Box>
            )
        },
        {
            id: 'security',
            label: 'Security',
            icon: <SecurityIcon />,
            content: (
                <Box>
                    <Typography variant="h6" sx={{ mb: 3 }}>Security Settings</Typography>
                    <Typography color="text.secondary">Security settings will be implemented here</Typography>
                </Box>
            )
        },
        {
            id: 'language',
            label: 'Language',
            icon: <LanguageIcon />,
            content: (
                <Box>
                    <Typography variant="h6" sx={{ mb: 3 }}>Language Settings</Typography>
                    <Typography color="text.secondary">Language settings will be implemented here</Typography>
                </Box>
            )
        }
    ];

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            height: '100vh',
            bgcolor: 'background.default'
        }}>
            <AppBar />
            <Box sx={{ display: 'flex', flex: 1 }}>
                <Box sx={{
                    width: 240,
                    bgcolor: 'background.paper',
                    height: '100%',
                    overflow: 'auto'
                }}>
                    <List>
                        {settingsSections.map((section) => (
                            <ListItemButton
                                key={section.id}
                                selected={selectedSection === section.id}
                                onClick={() => setSelectedSection(section.id)}
                                sx={{
                                    '&.Mui-selected': {
                                        bgcolor: 'action.selected',
                                    },
                                    '&:hover': {
                                        bgcolor: 'action.hover',
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    {section.icon}
                                </ListItemIcon>
                                <ListItemText primary={section.label} />
                            </ListItemButton>
                        ))}
                    </List>
                </Box>

                <Box sx={{ 
                    flex: 1, 
                    p: 3,
                    overflow: 'auto'
                }}>
                    {settingsSections.find(section => section.id === selectedSection)?.content}
                </Box>
            </Box>
        </Box>
    );
};

export default Settings;
