import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, useMemo, useEffect } from 'react';
import { lightTheme, darkTheme } from './Themes';
import Signup from './views/signup';
import Verify from './views/verify';
import Login from './views/login';
import ForgotPassword from './views/forgotpassword';
import Dashboard from './views/dashboard';
import Settings from './views/settings';
import DashboardHover from './views/dashboardHover';
import DashboardDropDown from './views/dashboardDropDown';
import '@fontsource/inter';

function App() {
	const [mode, setMode] = useState(() => {
		return localStorage.getItem('theme') || 'light';
	});
	
	const theme = useMemo(() => 
		mode === 'light' ? lightTheme : darkTheme,
		[mode]
	);

	useEffect(() => {
		localStorage.setItem('theme', mode);
	}, [mode]);

	const handleThemeToggle = () => {
		setMode((prevMode) => prevMode === 'light' ? 'dark' : 'light');
	};

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<BrowserRouter>
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/signup" element={<Signup />} />
					<Route path="/signup/verify" element={<Verify />} />
					<Route path="/" element={<Dashboard />} />
					<Route path="/v1" element={<DashboardHover />} />
					<Route path="/v2" element={<DashboardDropDown />} />	
					<Route 
						path="/settings" 
						element={
							<Settings 
								onToggleTheme={handleThemeToggle}
								isDarkMode={mode === 'dark'}
							/>
						} 
					/>
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;