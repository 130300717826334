import React, { useState } from 'react';
import { Box, List, Drawer, ListItemIcon, ListItemText, ListItemButton, Typography, IconButton, useTheme, useMediaQuery, Avatar, Badge, Popper, Paper, ClickAwayListener } from '@mui/material';
import {
    AccessTime as TimeIcon,
    BarChart as ExpenseIcon,
    Payment as PaymentIcon,
    Description as InvoiceIcon,
    Settings as SettingsIcon,
    Security as TrustIcon,
    Refresh as ReplenishIcon,
    NotificationsNone as NotificationIcon,
    Menu as MenuIcon,
    PlaylistAdd as TimeEntryIcon,
    Search as TimeSearchIcon,
    Receipt as NewExpenseIcon,
    FindInPage as SearchExpenseIcon,
    AccountBalanceWallet as MakePaymentIcon,
    KeyboardArrowRight as ArrowRightIcon,
    ArrowDownward,
    Gavel as MattersIcon,
    FolderOpen as NewMatterIcon,
    Search as SearchMattersIcon,
    Star as StarredMattersIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Logo from '../static/img/logo.png';
import DashboardContent from '../components/dashboard/DashboardContent';

const Header = ({ isMobile, onMenuClick }) => {
    const navigate = useNavigate();

    const handleProfileClick = () => {
        navigate('/signup'); 
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 4,
            px: 2,
            borderColor: 'divider',
            width: '100%',
            boxSizing: 'border-box',
            minWidth: 0
        }}>
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: { xs: 1, sm: 2 },
                minWidth: 0
            }}>
                {isMobile && (
                    <IconButton onClick={onMenuClick} sx={{ mr: 0 }}>
                        <MenuIcon />
                    </IconButton>
                )}
                <img src={Logo} alt="GoToBills Logo" style={{ height: '28px', width: 'auto' }} />
                <Typography 
                    variant="h4" 
                    fontWeight="bold"
                    sx={{ 
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    GoToBills
                </Typography>
            </Box>
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: { xs: 1, sm: 2 },
                minWidth: 0
            }}>
                <IconButton size="small">
                    <Badge badgeContent={3} color="error">
                        <NotificationIcon sx={{ fontSize: { xs: 20, sm: 24 } }} />
                    </Badge>
                </IconButton>
                <Box 
                    onClick={handleProfileClick}
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1, 
                        cursor: 'pointer',
                        minWidth: 0
                    }}
                >
                    <Avatar sx={{ 
                        width: { xs: 28, sm: 32 }, 
                        height: { xs: 28, sm: 32 } 
                    }} />
                    <Typography 
                        variant="subtitle2" 
                        sx={{ 
                            display: { xs: 'none', sm: 'block' },
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: 150
                        }}
                    >
                        Account Name
                    </Typography>
                    <ArrowDownward sx={{ fontSize: { xs: 16, sm: 20 } }} />
                </Box>
            </Box>
        </Box>
    );
};
    

const DashboardHover = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState('');
    const [mobileOpen, setMobileOpen] = useState(false);
    const [selectedPath, setSelectedPath] = useState('/');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleMenuEnter = (event, menuId) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(menuId);
    };

    const handleMenuLeave = () => {
        setAnchorEl(null);
        setOpenMenu('');
    };

    const handleContentChange = (path) => {
        setSelectedPath(path);
        setOpenMenu('');
        if (isMobile) setMobileOpen(false);
    };

    const menuItems = [
        {
            id: 'matters',
            icon: <MattersIcon />,
            label: 'Matters',
            subItems: [
                { id: 'newMatter', label: 'New Matter', icon: <NewMatterIcon />, path: '/matters/new' },
                { id: 'searchMatters', label: 'Search Matters', icon: <SearchMattersIcon />, path: '/matters/search' },
                { id: 'starredMatters', label: 'Starred Matters', icon: <StarredMattersIcon />, path: '/matters/starred' },
            ]
        },
        {
            id: 'time',
            icon: <TimeIcon />,
            label: 'Time',
            subItems: [
                { id: 'timeEntry', label: 'Time Entry', icon: <TimeEntryIcon />, path: '/time/entry' },
                { id: 'timeSearch', label: 'Search Time', icon: <TimeSearchIcon />, path: '/time/search' }
            ]
        },
        {
            id: 'expense',
            icon: <ExpenseIcon />,
            label: 'Expense',
            subItems: [
                { id: 'newExpense', label: 'New Expense', icon: <NewExpenseIcon />, path: '/expense/new' },
                { id: 'searchExpense', label: 'Search Expenses', icon: <SearchExpenseIcon />, path: '/expense/search' }
            ]
        },
        {
            id: 'payments',
            icon: <PaymentIcon />,
            label: 'Payments',
            subItems: [
                { id: 'makePayment', label: 'Make Payment', icon: <MakePaymentIcon />, path: '/payments/new' },
                { id: 'trust', label: 'Trust Account', icon: <TrustIcon />, path: '/payments/trust' },
                { id: 'replenish', label: 'Replenish', icon: <ReplenishIcon />, path: '/payments/replenish' }
            ]
        },
        {
            id: 'invoices',
            icon: <InvoiceIcon />,
            label: 'Invoices',
            subItems: [
                { id: 'drafts', label: 'Draft Invoices', icon: <InvoiceIcon />, path: '/invoices/drafts' },
                { id: 'sent', label: 'Sent Invoices', icon: <InvoiceIcon />, path: '/invoices/sent' }
            ]
        },
        // {
        //     id: 'settings',
        //     icon: <SettingsIcon />,
        //     label: 'Settings',
        //     path: '/settings'
        // }
    ];

    const HoverMenu = ({ items, open, anchorEl, onClose }) => {
        return (
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement="right-start"
                sx={{ zIndex: 1300 }}
                onMouseLeave={onClose}
            >
                <Paper 
                    elevation={4}
                    sx={{ 
                        width: 200,
                        borderRadius: 1,
                        bgcolor: 'background.paper',
                        mt: 1
                    }}
                >
                    <List dense>
                        {items.map((item) => (
                            <ListItemButton
                                key={item.id}
                                onClick={() => handleContentChange(item.path)}
                                selected={selectedPath === item.path}
                                sx={{
                                    py: 1,
                                    '&.Mui-selected': {
                                        bgcolor: 'primary.lighter',
                                    },
                                    '& .MuiListItemIcon-root': {
                                        minWidth: 32,
                                    },
                                    '& .MuiTypography-root': {
                                        fontSize: '0.875rem',
                                    }
                                }}
                            >
                                <ListItemIcon sx={{ 
                                    fontSize: 20,
                                    color: selectedPath === item.path ? 'primary.main' : 'inherit' 
                                }}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText 
                                    primary={item.label}
                                    primaryTypographyProps={{
                                        color: selectedPath === item.path ? 'primary.main' : 'inherit'
                                    }}
                                />
                            </ListItemButton>
                        ))}
                    </List>
                </Paper>
            </Popper>
        );
    };

    const sidebarContent = (
        <Box sx={{
            width: { xs: 280, md: 72 },
            bgcolor: 'background.paper',
            height: '100vh',
            position: 'relative',
        }}>
            <List component="nav" sx={{ pt: 0 }}>
                {menuItems.map((item) => (
                    <Box key={item.id}>
                        <ListItemButton
                            onMouseEnter={(e) => item.subItems && handleMenuEnter(e, item.id)}
                            onClick={() => !item.subItems && handleContentChange(item.path)}
                            selected={selectedPath === item.path || (item.subItems && item.subItems.some(sub => sub.path === selectedPath))}
                            sx={{
                                '&.Mui-selected': {
                                    bgcolor: 'primary.lighter',
                                    '&:hover': {
                                        bgcolor: 'primary.light',
                                    },
                                    '& .MuiListItemIcon-root': {
                                        color: 'primary.main',
                                    }
                                },
                                '&:hover': {
                                    bgcolor: 'action.hover',
                                },
                                minHeight: 48,
                                px: 2.5,
                                justifyContent: 'center',
                                '& .MuiListItemIcon-root': {
                                    minWidth: 0,
                                    mr: { xs: 3, md: 0 },
                                    justifyContent: 'center',
                                },
                                '& .MuiListItemText-root': {
                                    display: { md: 'none' }
                                }
                            }}
                        >
                            <ListItemIcon sx={{ 
                                fontSize: 24,
                                color: (selectedPath === item.path || (item.subItems && item.subItems.some(sub => sub.path === selectedPath))) 
                                    ? 'primary.main' 
                                    : 'inherit'
                            }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText 
                                primary={item.label}
                                primaryTypographyProps={{
                                    color: (selectedPath === item.path || (item.subItems && item.subItems.some(sub => sub.path === selectedPath)))
                                        ? 'primary.main'
                                        : 'inherit'
                                }}
                            />
                        </ListItemButton>
                        {item.subItems && (
                            <HoverMenu
                                items={item.subItems}
                                open={openMenu === item.id}
                                anchorEl={anchorEl}
                                onClose={handleMenuLeave}
                            />
                        )}
                    </Box>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Header isMobile={isMobile} onMenuClick={() => setMobileOpen(!mobileOpen)} />
            <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                {isMobile ? (
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        open={mobileOpen}
                        onClose={() => setMobileOpen(false)}
                        ModalProps={{
                            keepMounted: true 
                        }}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: 280,
                                boxSizing: 'border-box',
                            },
                        }}
                    >
                        {sidebarContent}
                    </Drawer>
                ) : (
                    <Box
                        component="nav"
                        sx={{ width: { xs: 280, md: 72 }, flexShrink: 0 }}
                    >
                        {sidebarContent}
                    </Box>
                )}
                <Box sx={{ flex: 1, p: 3, overflow: 'auto' }}>
                    <DashboardContent selectedPath={selectedPath} />
                </Box>
            </Box>
        </Box>
    );
};

export default DashboardHover;