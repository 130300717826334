import React from 'react';
import { Typography, Box } from '@mui/material';

const Replenish = () => (
    <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>Replenish Account</Typography>
        <Box sx={{ p: 2, }}>
            <Typography>Replenish account functionality will be implemented here</Typography>
        </Box>
    </Box>
);

export default Replenish;