import React from 'react';
import { Typography, Paper, Grid2, Box } from '@mui/material';

const TimeEntry = () => (
    <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>Time Entry</Typography>
        <Grid2 container spacing={3}>
            <Grid2 item xs={12} md={6}>
                <Paper sx={{ p: 2, }}>
                    <Typography variant="subtitle1">Today's Hours</Typography>
                    <Typography variant="h4">6.5</Typography>
                </Paper>
            </Grid2>
        </Grid2>
    </Box>
);

export default TimeEntry;