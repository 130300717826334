import React, { useState } from 'react';
import { Box, Container, TextField, Button, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Verify = () => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (otp.length !== 6) {
      setError('Please enter a valid 6-digit OTP');
      return;
    }
    console.log('OTP :', otp);
    navigate('/dashboard');
  };

  return (
    <Box sx={{ py: 4, bgcolor: 'background.default', minHeight: '100vh' }}>
      <Container maxWidth="sm">
        <Typography variant="h4" component="h1" align="center" gutterBottom sx={{ mb: 4 }}>
          Verify Your Email
        </Typography>
        <Paper elevation={3} sx={{ 
          p: 4, 
          borderRadius: 2, 
          bgcolor: 'background.paper',
          '&:hover': { 
            boxShadow: 6
          } 
        }}>
          <Typography variant="body1" align="center" sx={{ mb: 3 }}>
            Please enter the verification code sent to your email address
          </Typography>
          <Box 
            component="form" 
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3
            }}
          >
            <TextField 
              required
              name="otp"
              label="Verification Code"
              value={otp}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, '');
                if (value.length <= 6) setOtp(value);
              }}
              error={!!error}
              helperText={error}
              fullWidth
              inputProps={{
                maxLength: 6,
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }}
            />
            <Button 
              type="submit" 
              variant="contained" 
              fullWidth 
              sx={{ 
                mt: 2,
                height: '48px', 
                fontSize: '1.1rem',
                color: (theme) => theme.palette.mode === 'light' ? 'white' : 'black'
              }}
            >
              Verify
            </Button>
          </Box>
          <Box sx={{ 
            mt: 4,
            textAlign: 'center',
            paddingTop: 2,
            borderTop: 1,
            borderColor: 'divider'
          }}>
            <Typography variant="body2" color="text.secondary">
              Didn't receive the code?{' '}
              <Button 
                color="primary" 
                onClick={() => {
                  // TODO:
                  console.log('Resend');
                }}
                sx={{ 
                  textTransform: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Resend
              </Button>
            </Typography>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Verify; 