import { createTheme } from '@mui/material';

const getTheme = (mode) => createTheme({
	palette: {
		mode,
		background: {
			default: mode === 'light' ? '#FFFFFF' : '#1E2931',
			paper: mode === 'light' ? '#FFFFFF' : '#1E2931',
		},
		primary: {
			main: mode === 'light' ? '#000000' : '#FFFFFF',
			light: mode === 'light' ? '#999' : '#AAA',
			dark: mode === 'light' ? '#000000' : '#FFFFFF',
		},
		text: {
			primary: mode === 'light' ? '#000000' : '#FFFFFF',
			secondary: mode === 'light' ? '#000000' : '#E0E0E0',
		}
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					backgroundColor: mode === 'light' ? '#FFFFFF' : '#1E2931',
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderColor: mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
						},
						'&:hover fieldset': {
							borderColor: mode === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
						},
						'&.Mui-focused fieldset': {
							borderColor: mode === 'light' ? '#000000' : '#FFFFFF',
						},
						color: mode === 'light' ? '#000000' : '#FFFFFF',
					},
					'& .MuiInputLabel-root': {
						color: mode === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
						'&.Mui-focused': {
							color: mode === 'light' ? '#000000' : '#FFFFFF',
						},
					},
					'& .MuiFormHelperText-root': {
						color: mode === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: mode === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: mode === 'light' ? '#000000' : '#FFFFFF',
					},
					color: mode === 'light' ? '#000000' : '#FFFFFF',
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					color: mode === 'light' ? '#000000' : '#FFFFFF',
				},
			},
		},
	},
});

export const lightTheme = getTheme('light');
export const darkTheme = getTheme('dark');