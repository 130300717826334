import React from 'react';
import { Box, Typography } from '@mui/material';

const TimeSearch = () => (
    <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>Time Search</Typography>
        <Box sx={{  p: 2 }}>
            <Typography>Time search functionality will be implemented here</Typography>
        </Box>
    </Box>
);

export default TimeSearch;