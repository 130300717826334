import axios from './axios';

export const accountService = {
  signup: async (userData) => {
    return axios.post('/firm/signup', JSON.stringify( {
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      firm_name: userData.firmName,
      phone_number: userData.phoneNumber,
      password: userData.password,
      service_plan_code: userData.servicePlan.toLowerCase(),
      firm_size: userData.firmSize
    } )
  );
  },
  
};

export default accountService; 