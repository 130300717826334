import React, { useState } from 'react';
import {
	Box,
	Container,
	TextField,
	Button,
	Typography,
	Paper,
	Link,
	Alert,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);

	const validateEmail = (email) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	};

	const handleChange = (event) => {
		const value = event.target.value;
		setEmail(value);

		if (!value) {
			setEmailError('Email is required');
		} else if (!validateEmail(value)) {
			setEmailError('Please enter a valid email');
		} else {
			setEmailError('');
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!emailError && validateEmail(email)) {
			console.log('Password reset requested for:', email);
			setIsSubmitted(true);
		}
	};

	return (
		<Box sx={{ py: 4 }}>
			<Container maxWidth="sm">
				<Typography variant="h4" component="h1" align="center" gutterBottom sx={{ mb: 4 }}>
					Reset Password
				</Typography>
				<Paper elevation={3} sx={{ p: 4, borderRadius: 2, bgcolor: '#FFFFFF', '&:hover': { boxShadow: '0 8px 24px rgba(74, 111, 165, 0.12)' } }} >
					{!isSubmitted ? (
						<Box component="form" onSubmit={handleSubmit}>
							<Typography variant="body1" sx={{ mb: 3 }}>
								Please enter your email address and we'll send you instructions to reset your password.
							</Typography>
							<TextField
								required
								fullWidth
								name="email"
								label="Email"
								type="email"
								value={email}
								onChange={handleChange}
								error={!!emailError}
								helperText={emailError}
							/>
							<Button
								type="submit"
								variant="contained"
								fullWidth
								sx={{ mt: 3, height: '48px', fontSize: '1.1rem' }}
							>
								Send Reset Link
							</Button>
						</Box>
					) : (
						<Box>
							<Alert severity="success" sx={{ mb: 3 }}>
								If an account exists for {email}, you will receive password reset instructions.
							</Alert>
							<Typography variant="body1" sx={{ mb: 2 }}>
								Please check your email and follow the instructions to reset your password.
							</Typography>
						</Box>
					)}
					<Box sx={{ mt: 2, textAlign: 'center' }}>
						<Link component={RouterLink} to="/login" color="primary">
							Back to Login
						</Link>
					</Box>
				</Paper>
			</Container>
		</Box>
	);
};

export default ForgotPassword;